<template>
  <div v-loading="!devicesLoaded" style="margin: 10px; width: calc(100vw - 85px)">
    <el-dialog
      :title="selectedVehicle && selectedVehicle.name"
      :visible.sync="showQrcode"
      width="50%"
    >
      <canvas
        id="qrcode1"
        style="padding: 0;margin: auto; display: block;"
      ></canvas>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="printQrcode"><i class="el-icon-printer" style="font-size: 20px"></i></el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="isOpenAssociateGeofencesForm"
      width="80%"
      :title="$t('settings.vehicle_associate_geofences')+ ' ' + (selectedVehicle && selectedVehicle.name)"
    >
      <div v-if="loading" style="height: 300px">
        <div>
          <div class="el-loading-spinner">
            <div class="el-icon-loading"></div>
            <div style="padding: 50px"><el-progress :percentage="percentage"></el-progress></div>
          </div>
        </div>
      </div>
      <el-transfer
        v-else
        v-model="vehicleGeofences"
        filterable
        :filter-placeholder="$t('settings.search')"
        :titles="[$t('settings.select'), $t('settings.transfer_selected')]"
        :props="{
          key: 'id',
          label: 'name'
        }"
        :data="dialogGeofences"
        :render-content="renderFunc"
      >
      </el-transfer>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="info"
          size="small"
          @click="handleCancelAssociateGeofencesForm"
        >{{ $t('settings.form_cancel') }}</el-button>
        <el-button
          :loading="loading"
          type="success"
          size="small"
          @click="handleSubmitAssociateGeofencesForm"
        >{{ $t('settings.form_save') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :visible.sync="isOpenVehicleForm" :title="$t('settings.vehicle_edit')" top="4vh">
      <el-form style="height: calc(90vh - 150px); overflow: scroll; padding: 0">
        <el-form-item :label="$t('settings.vehicle_form_name')">
          <el-input v-model="vehicleName" />
        </el-form-item>
        <div class="form-item-block">
          <div class="form-item-row">
            <el-form-item class="form-item-block-left" :label="$t('settings.vehicle_form_group')">
              <el-select
                v-model="selectedGroup"
                style="width: 100%; height: 35px"
                :placeholder="$t('settings.vehicle_form_groups_placeholder')"
                value=""
              >
                <el-option v-for="item in groups" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item class="form-item-block-right" :label="$t('settings.vehicle_form_category')">
              <el-select
                v-model="selectedCategory"
                style="width: 100%; height: 35px"
                :placeholder="$t('settings.vehicle_form_category_placeholder')"
                value=""
              >
                <el-option v-for="item in categories" :key="item.value" :label="item.text" :value="item.value" />
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="form-item-block">
          <div class="form-item-row">
            <el-form-item class="form-item-block-left" :label="$t('settings.vehicle_form_model')">
              <el-input v-model="vehicleModel" style="width: 100%;" />
            </el-form-item>
            <el-form-item class="form-item-block-right" :label="$t('settings.vehicle_form_fuel_tank_capacity')">
              <el-input-number v-model="vehicleFuelTankCapacity" style="width: 100%;" :min="0" :precision="0" />
            </el-form-item>
          </div>
        </div>
        <div style="margin-top: 15px">
          <el-form-item>
            <el-checkbox v-model="applyConfigurationsToAll" :label="$t('settings.vehicle_form_apply_to_all')" />
          </el-form-item>
        </div>
        <el-tabs type="border-card">
          <el-tab-pane>
            <span slot="label"><el-tooltip :content="$t('settings.speed_limit')"><i style="font-size: 20px" class="fas fa-tachometer-alt"></i></el-tooltip></span>
            <el-row>
              <el-col :span="8">
                <el-form-item class="" :label="$t('settings.vehicle_form_speed_limit')">
                  <div>
                    <el-input-number v-model="speedLimit" :min="0" :precision="0" :disabled="overspeedByRoad" />
                  </div>
                  <div>
                    <el-switch v-model="overspeedByRoad" :active-text="$t('Use road speed limit')"></el-switch>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item class="" :label="$t('settings.vehicle_form_speed_minimal_duration')">
                  <el-input-number v-model="overspeedMinimalDuration" :min="0" :precision="2" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item class="" :label="$t('settings.max_speed_threshold')">
                  <el-input-number v-model="overspeedThreshold" :min="0" :precision="0" :disabled="!overspeedByRoad" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <el-tooltip :content="$t('settings.alert_idle')">
                <i style="font-size: 20px" class="fas fa-pause"></i>
              </el-tooltip>
            </span>
            <el-row>
              <el-col :span="10">
                <el-form-item class="" :label="$t('settings.vehicle_form_speed_minimal_duration')">
                  <el-input-number v-model="minIdleTime" :min="0" :precision="0" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label"><el-tooltip :content="$t('settings.route_history')"><i style="font-size: 20px" class="fas fa-route"></i></el-tooltip></span>
            <el-row>
              <el-col :span="10">
                <el-form-item class="" :label="$t('settings.vehicle_route_color')">
                  <el-color-picker
                    v-model="routeColor"
                    @active-change="(c) => routeColorActive = c"
                  >
                  </el-color-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label"><el-tooltip :content="$t('settings.driver')"><i style="font-size: 20px" class="fas fa-address-card"></i></el-tooltip></span>

            <el-form-item class="" :label="$t('settings.vehicle_associated_driver')">
              <el-select v-model="driverUniqueId" clearable placeholder="Seleccionar Motorista">
                <el-option
                  v-for="item in sortedDrivers"
                  :key="item.uniqueId"
                  :label="item.name"
                  :value="item.uniqueId"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item class="" :label="$t('settings.unidentified_driver_duration')">
              <el-input-number v-model="minUnidentifiedDriverTime" :min="0" :precision="0" />
            </el-form-item>

          </el-tab-pane>
          <el-tab-pane>
            <span slot="label"><el-tooltip :content="$t('settings.alert_temperature')"><i style="font-size: 20px" class="fas fa-temperature-high"></i></el-tooltip></span>
            <el-row>
              <el-col :span="8">
                <el-form-item class="" :label="$t('settings.min_temperature')">
                  <el-input-number v-model="minTemperature" :min="-80" :max="174" :precision="0" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item class="" :label="$t('settings.max_temperature')">
                  <el-input-number v-model="maxTemperature" :min="-80" :max="174" :precision="0" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item class="" :label="$t('settings.calibrate_temperature')">
                  <el-input-number v-model="calibrateTemperature" :min="-80" :max="174" :precision="0" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label"><el-tooltip :content="$t('settings.alert_digital_port')"><i style="font-size: 20px" class="fas fa-door-open"></i></el-tooltip></span>
            <el-row>
              <el-col :span="20">
                <el-form-item class="" :label="$t('settings.max_digital_port_open_time')">
                  <el-input-number v-model="maxDigitalPortOpenTime" :min="0" :precision="0" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <el-form-item
          :label="selectedVehicle &&
            selectedVehicle.attributes.integration &&
            selectedVehicle.attributes.integration.includes('monitrip') ? 'Licença de Viagem' : $t('settings.vehicle_notes')"
        >
          <el-input v-model="vehicleNotes" type="textarea" />
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button
          v-if="selectedVehicle && selectedVehicle.attributes.integration &&
            selectedVehicle.attributes.integration.includes('monitrip')"
          v-loading="loading"
          type="warning"
          size="small"
          @click="monitriip"
        >
          {{ `Monitriip ${selectedVehicle.attributes.monitrip ? 'Terminar' : 'Iniciar'} Viagem` }}
        </el-button>
        <el-button
          type="info"
          size="small"
          @click="handleCancelVehicleForm"
        >{{ $t('settings.form_cancel') }}</el-button>
        <el-button
          type="success"
          size="small"
          :loading="loading"
          @click="handleSubmitVehicleForm"
        >{{ $t('settings.form_save') }}</el-button>
        <el-progress v-if="loading" :percentage="percentage" style="padding-top: 5px"></el-progress>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="isOpenVehicleKmsForm" :title="$t('settings.vehicle_kms_form')">
      <el-form>
        <el-form-item :label="$t('settings.vehicle_kms_form_date')">
          <el-date-picker
            v-model="vehicleDateKms"
            type="datetime"
            :placeholder="$t('settings.vehicle_kms_form_date_placeholder')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('settings.vehicle_kms_form_total_kms')">
          <el-input-number v-model="vehicleKms" :min="0" :precision="1" />
        </el-form-item>
        <el-form-item :label="$t('settings.vehicle_total_hours')">
          <el-input-number v-model="vehicleHours" :min="0" :precision="1" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="info"
          size="small"
          @click="handleCancelVehicleKmsForm"
        >{{ $t('settings.form_cancel') }}</el-button>
        <el-button
          :loading="loading"
          type="success"
          size="small"
          @click="handleSubmitVehicleKmsForm"
        >{{ $t('settings.form_confirm') }}</el-button>
      </div>
    </el-dialog>
    <el-table
      v-el-table-infinite-scroll="load"
      :data="filteredDevices.slice(0, count)"
      height="calc(100vh - 100px)"
      @row-dblclick="handleDoubleClick"
    >
      <el-table-column
        :label="$t('settings.vehicle_name')"
        prop="name"
        sortable=""
        width="200"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          <span :style="`color: ${scope.row.attributes.routeColor}`">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('settings.vehicle_licenseplate')"
        prop="attributes.license_plate"
        sortable=""
        width="220"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        :label="$t('settings.vehicle_group')"
        :formatter="groupRenderer"
        prop="groupId"
        sortable=""
        :sort-method="sortGroup"
        :filters="groupsFilter"
        :filter-method="filterHandler"
        width="180"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        :label="$t('settings.driver')"
        :formatter="driverFormatter"
        prop="attributes"
        sortable=""
        width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        :label="$t('settings.vehicle_model')"
        prop="model"
        sortable=""
        width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        v-if="!isMobile"
        :label="$t('settings.vehicle_speed_limit')"
        :formatter="alertSpeedRenderer"
        prop="attributes.speedLimit"
        sortable=""
        align="right"
        width="220"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        :formatter="formatTotalDistance"
        sortable
        :label="$t('settings.vehicle_kms')"
        align="right"
        prop=""
        width="150"
      >
      </el-table-column>
      <el-table-column
        id="lastPosition"
        sortable
        :formatter="(row, column, cellValue) => new Date(cellValue).toLocaleString()"
        :label="$t('settings.vehicle_lastposition')"
        align="right"
        prop="position.fixTime"
        width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        :label="$t('settings.vehicle_lastposition_address')"
        prop="position.address"
        width="220"
      >
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        :label="$t('settings.vehicle_notes')"
        prop="attributes.notes"
        width="225"
      >
      </el-table-column>
      <el-table-column width="175" align="right" label="" fixed="right">
        <template slot="header">
          <el-button :loading="downloadLoading" icon="el-icon-document" type="primary" @click="handleDownload">Excel</el-button>
        </template>
        <template slot-scope="scope">
          <el-tooltip :content="$t('settings.edit')" placement="top">
            <el-button
              size="mini"
              @click="handleEdit(scope.row)"
            ><i class="fas fa-edit"></i></el-button>
          </el-tooltip>
          <el-tooltip :content="$t('settings.vehicle_edit_kms')" placement="top">
            <el-button
              size="mini"
              :disabled="Boolean(scope.row.attributes.xpert && scope.row.position && scope.row.position.attributes.odometer)"
              type="info"
              @click="handleUpdateVehicleKms(scope.row)"
            ><i class="fas fa-road"></i></el-button>
          </el-tooltip>
          <el-tooltip :content="$t('settings.vehicle_associate_geofences')" placement="top">
            <el-button
              size="mini"
              type="primary"
              @click="handleAssociateGeofences(scope.row)"
            ><i class="fas fa-map-marked"></i></el-button>
          </el-tooltip>
          <el-tooltip :content="$t('settings.vehicle_get_qrcode')" placement="top">
            <el-button
              size="mini"
              type="primary"
              @click="qrcode(scope.row)"
            ><i class="fas fa-qrcode"></i></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total"
      :total="filteredDevices.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { serverBus, vm } from '@/main'
import { traccar } from '@/api/traccar-api'
import * as lnglat from '../../../utils/lnglat'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import QRCode from 'qrcode'
import print from 'print-js'
import { pinmeapi } from '@/api/pinme'
import * as alertType from '@/alerts/alertType'
import { getDeviceTotalDistance } from '@/utils/device'
import axios from 'axios'

const MIN_SPEED_FOR_ROAD = 50
export default {
  name: 'Vehicles',
  data() {
    return {
      dialogGeofences: [],
      url: '',
      showQrcode: false,
      count: 20,
      isOpenVehicleForm: false,
      isOpenVehicleKmsForm: false,
      isOpenAssociateGeofencesForm: false,
      minIdleTime: 5,
      minUnidentifiedDriverTime: 0,
      selectedVehicle: null,
      vehicleName: '',
      vehicleModel: '',
      speedLimit: 0,
      overspeedByRoad: false,
      overspeedThreshold: 0,
      overspeedMinimalDuration: 0,
      vehicleTotalKms: 0,
      vehicleDateKms: new Date(),
      vehicleKms: 0,
      vehicleHours: 0,
      vehicleFuelTankCapacity: 0,
      vehicleGeofences: [],
      vehicleNotes: '',
      selectedGroup: null,
      selectedCategory: null,
      groupsFilter: null,
      downloadLoading: false,
      routeColor: '',
      routeColorActive: '',
      driverUniqueId: null,
      minTemperature: 0,
      maxTemperature: 0,
      calibrateTemperature: 0,
      maxDigitalPortOpenTime: 0,
      applyConfigurationsToAll: false
    }
  },
  computed: {
    sortedDrivers() {
      const _drivers = this.drivers.slice()
      return _drivers.sort((a, b) => { return a.name.localeCompare(b.name) })
    },
    filteredDevices() {
      return this.devices.filter(
        data => !this.search || (data.name && data.name.toLowerCase().includes(this.search.toLowerCase())) ||
        (data.attributes.license_plate && data.attributes.license_plate.toLowerCase().includes(this.search.toLowerCase())) ||
        (data.model && data.model.toLowerCase().includes(this.search.toLowerCase()))
      )
    },
    ...mapGetters(['devicesLoaded', 'user', 'groups', 'geofences', 'drivers', 'search', 'loading']),
    percentage: {
      get() { return this.$store.getters.percentage },
      set(value) { this.$store.commit('transient/SET_PERCENTAGE', value) }
    },
    isMobile() {
      return lnglat.isMobile()
    },
    devices() {
      return vm.$store.getters.devices.sort((a, b) => (a.name > b.name) ? 1 : -1)
    },
    categories() {
      return [
        { value: 'car', text: this.$t('settings.vehicle_icon_car') },
        { value: 'Generator', text: this.$t('Generator') },
        { value: 'Forklift', text: this.$t('Forklift') },
        { value: 'ambulance', text: this.$t('settings.vehicle_icon_ambulance') },
        { value: 'van', text: this.$t('settings.vehicle_icon_van') },
        { value: 'truck', text: this.$t('settings.vehicle_icon_truck') },
        { value: 'truck2', text: this.$t('settings.vehicle_icon_truck2') },
        { value: 'towtruck', text: this.$t('settings.vehicle_icon_towtruck') },
        { value: 'trailer', text: this.$t('settings.vehicle_icon_trailer') },
        { value: 'digger', text: this.$t('settings.vehicle_icon_digger') },
        { value: 'roadroller', text: this.$t('settings.vehicle_icon_roadroller') },
        { value: 'crane', text: this.$t('settings.vehicle_icon_crane') },
        { value: 'dumpingtruck', text: this.$t('settings.vehicle_icon_dumpingtruck') },
        { value: 'default', text: this.$t('settings.vehicle_icon_default') },
        { value: 'arrow', text: this.$t('settings.vehicle_icon_arrow') },
        { value: 'bus', text: this.$t('settings.vehicle_icon_bus') },
        { value: 'minibus', text: this.$t('settings.vehicle_icon_minibus') },
        { value: 'tractor', text: this.$t('settings.vehicle_icon_tractor') },
        { value: 'motorcycle', text: this.$t('settings.vehicle_icon_motorcycle') },
        { value: 'pickup', text: this.$t('settings.vehicle_icon_pickup') },
        { value: 'bulldozer', text: this.$t('settings.vehicle_icon_bulldozer') },
        { value: 'cistern', text: this.$t('settings.vehicle_icon_cistern') },
        { value: 'mixer', text: this.$t('settings.vehicle_icon_mixer') },
        { value: 'boat', text: this.$t('settings.vehicle_icon_boat') }
      ].sort((e1, e2) => { return e1.text.localeCompare(e2.text) })
    }
  },
  watch: {
    groups() {
      this.groupsFilter = this.groups.map(g => {
        return { text: g.name, value: g.id }
      })
    },
    devicesLoaded() {
      this.setPositions()
    },
    vehicleOverspeedByRoad(value) {
      if (value) {
        this.speedLimit = MIN_SPEED_FOR_ROAD
      }
    }
  },
  methods: {
    async setPositions() {
      console.log('setPOsitions')
      const positions = await traccar.positions().then(r => r.data)
      positions.forEach(p => {
        const device = this.devices.find(d => d.id === p.deviceId)
        if (device) {
          device.position = p
          this.$store.commit('user/SET_DEVICE', device)
        }
      })
    },
    driverFormatter(r, c, value) {
      const driver = this.drivers.find(d => d.uniqueId === value.driverUniqueId)
      return driver ? driver.name : value.driverUniqueId
    },
    async printQrcode() {
      const dataUrl = await QRCode.toDataURL(this.url)
      print({
        printable: dataUrl,
        type: 'image',
        header: this.selectedVehicle.name,
        documentTitle: this.selectedVehicle.name
      })
    },
    async qrcode(row) {
      this.showQrcode = true
      this.selectedVehicle = row
      const token = await pinmeapi.getDeviceToken(this.selectedVehicle.id)
      this.url = 'https://fieldmap.net/?token=' + token
      setTimeout(() => {
        const canvas = document.getElementById('qrcode1')
        QRCode.toCanvas(canvas, this.url)
          .then(url => {
            console.log(url)
          })
          .catch(err => {
            alert(err)
            console.error(err)
          })
      }, 100)
    },
    load() {
      this.count += 20
    },
    sortGroup(a, b) {
      a = this.groupRenderer(undefined, undefined, a.groupId)
      b = this.groupRenderer(undefined, undefined, b.groupId)
      return a.localeCompare(b)
    },
    filterHandler(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    findFeatureByDeviceId(deviceId) {
      return lnglat.findFeatureByDeviceId(deviceId)
    },
    formatTotalDistance(device) {
      if (device.position) {
        return Math.round(getDeviceTotalDistance(device, device.position))
      } else {
        return ''
      }
    },
    getLocalDate(date) {
      return new Date(date.getTime() - new Date().getTimezoneOffset() * 60 * 1000)
    },
    handleDownload() {
      this.downloadLoading = true
      import('../../../utils/ExportExcel').then(excel => {
        const tHeader = [
          this.$t('settings.vehicle_name'),
          this.$t('settings.vehicle_licenseplate'),
          this.$t('settings.vehicle_group'),
          this.$t('settings.vehicle_model'),
          this.$t('settings.vehicle_speed_limit'),
          this.$t('settings.vehicle_kms'),
          this.$t('settings.vehicle_lastposition'),
          this.$t('settings.vehicle_lastposition_address'),
          this.$t('settings.vehicle_notes'),
          'Latitude',
          'Longitude'
        ]

        Vue.$log.debug(this.user)
        if (this.user.userLimit === -1) {
          // User administrator
          tHeader.push(this.$t('settings.vehicle_gsm'))
        }

        const data = this.filteredDevices.map(v => [
          v.name,
          v.attributes.license_plate,
          this.groups.find(g => g.id === v.groupId) ? this.groups.find(g => g.id === v.groupId).name : '',
          v.model,
          Math.round(v.attributes.speedLimit * 1.852),
          this.formatTotalDistance(v),
          v.position && this.getLocalDate(new Date(v.position.fixTime)),
          v.position ? v.position.address : '',
          v.attributes.notes,
          v.position && v.position.latitude,
          v.position && v.position.longitude,
          this.user.userLimit === -1 && v.attributes.customer_gsm ? v.phone : ''
        ]
        )
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: '',
          autoWidth: false,
          bookType: 'xlsx'
        })
        this.downloadLoading = false
      })
    },
    handleCancelAssociateGeofencesForm() {
      this.isOpenAssociateGeofencesForm = false
    },
    async handleSubmitAssociateGeofencesForm() {
      try {
        this.$store.commit('transient/SET_LOADING', true)
        for (const g of this.selectedVehicle.geofences) {
          if (!this.vehicleGeofences.includes(g.id)) {
            const permission = {
              deviceId: this.selectedVehicle.id,
              geofenceId: g.id
            }
            await traccar.deletePermission(permission)
          }
        }
        const permissions = []
        for (const gId of this.vehicleGeofences) {
          const geofence = this.selectedVehicle.geofences.find(g => g.id === gId)
          if (!geofence) {
            permissions.push({
              deviceId: this.selectedVehicle.id,
              geofenceId: gId
            })
          }
        }
        await traccar.addAllPermissions(permissions)
        this.$message({
          message: this.$t('settings.vehicle_geofences_updated'),
          type: 'success',
          duration: 10 * 1000
        })
        this.isOpenAssociateGeofencesForm = false
      } catch (e) {
        this.$message.error(e.message)
      }
      this.$store.commit('transient/SET_LOADING', false)
    },
    async handleUpdateVehicleKms(row) {
      this.selectedVehicle = row
      const feature = this.findFeatureByDeviceId(row.id)
      let p
      if (feature) {
        p = feature.properties
      } else {
        const response = await traccar.positions()
        p = response.data.find(a => a.deviceId === this.selectedVehicle.id)
      }

      if (this.canEditVehicleKms(p)) {
        if (p) {
          this.vehicleKms = p.attributes.totalDistance / 1000
          this.vehicleHours = p.attributes.hours / (1000 * 60 * 60)
        }

        this.vehicleDateKms = null
        this.$store.commit('transient/SET_LOADING', false)
        this.isOpenVehicleKmsForm = true
      } else {
        const h = this.$createElement
        this.$msgbox({
          message: h('p', null, this.$t('settings.edit_vehicle_kms_warning')),
          title: this.$t('settings.vehicle_kms'),
          showCancelButton: false,
          showClose: false,
          confirmButtonText: 'OK'
        })
      }
    },
    canEditVehicleKms(position) {
      if (this.selectedVehicle.attributes['report.ignoreOdometer']) {
        return true
      }

      return !(position && position.attributes.odometer)
    },
    handleCancelVehicleKmsForm() {
      this.isOpenVehicleKmsForm = false
      this.clearFormData()
    },
    async handleSubmitVehicleKmsForm() {
      this.$store.commit('transient/SET_LOADING', true)
      const self = this
      const h = this.$createElement
      let incrementKms = 0
      let incrementHours = 0
      const messages = []

      try {
        if (this.vehicleDateKms) {
          const response = await traccar.trips([this.selectedVehicle.id], this.vehicleDateKms, new Date())
          if (response.data) {
            console.log(response.data)
            incrementKms = Math.round(response.data.reduce((a, b) => a + b.distance, 0) / 1000)
            incrementHours = response.data.reduce((a, b) => a + b.duration, 0) / (1000 * 60 * 60)
            const date = this.$moment(this.vehicleDateKms).format('YYYY-MM-DD HH:mm:ss')

            messages.push(
              h('span', null, date + ' - ' + this.vehicleKms + ' kms'),
              h('br'),
              h('span', null, this.$t('settings.vehicle_kms_traveled') + ' ' + date + ' - ' + incrementKms + ' kms'),
              h('br'),
              h('span', null, this.$t('settings.vehicle_kms_current') + ' - ' + (this.vehicleKms + incrementKms) + ' kms'),
              h('br'),
              h('hr'),
              h('br'),
              h('span', null, date + ' - ' + this.vehicleHours + ' H'),
              h('br'),
              h('span', null, this.$t('settings.vehicle_hours_traveled') + ' ' + date + ' - ' + (incrementHours).toFixed(2) + ' H'),
              h('br'),
              h('span', null, this.$t('settings.vehicle_hours_current') + ' - ' + (this.vehicleHours + incrementHours).toFixed(2) + ' H')
            )
          }
        } else {
          messages.push(
            h('span', null, this.$t('settings.vehicle_kms_current') + ' - ' + (this.vehicleKms + incrementKms) + ' kms'),
            h('br'),
            h('span', null, this.$t('settings.vehicle_hours_current') + ' - ' + (this.vehicleHours + incrementKms).toFixed(2) + ' H')
          )
        }

        this.$store.commit('transient/SET_LOADING', false)
        this.$msgbox({
          message: h('p', null, messages),
          title: this.$t('settings.vehicle_kms'),
          showCancelButton: true,
          showClose: false,
          confirmButtonText: 'OK'
        }).then(action => {
          this.$log.debug(action)
          self.vehicleTotalKms = self.vehicleKms + incrementKms

          const accumulator = {
            deviceId: self.selectedVehicle.id,
            totalDistance: self.vehicleTotalKms * 1000,
            hours: self.vehicleHours * (1000 * 60 * 60)
          }

          traccar.updateDeviceAccumulators(self.selectedVehicle.id, accumulator).then(() => {
            this.$message({
              message: this.$t('settings.vehicle_kms_updated'),
              type: 'success',
              duration: 5 * 1000
            })
            this.isOpenVehicleKmsForm = false
          }).catch(reason => {
            if (reason.response &&
              reason.response.data &&
              (reason.response.data.startsWith('Manager access required') ||
              reason.response.data.startsWith('Account is readonly'))) {
              this.$message({
                message: this.$t('settings.vehicle_edit_not_allowed'),
                type: 'warning',
                duration: 5 * 1000
              })
            } else {
              Vue.$log.error(reason)
              this.$alert(reason)
            }
            this.isOpenVehicleKmsForm = false
          })
        }).catch(() => {

        })
      } catch (e) {
        Vue.$log.error(e)
        this.isOpenVehicleKmsForm = false
      }
    },
    handleCancelVehicleForm() {
      this.isOpenVehicleForm = false
      this.clearFormData()
    },
    async monitriip() {
      try {
        await this.$confirm(
          `Deseja ${this.selectedVehicle.attributes.monitrip ? 'terminar' : 'iniciar'} a viagem?`,
          'Monitriip')
        if (!this.vehicleNotes) {
          await this.$alert('Por favor introduza a licença de viagem.')
          return
        }
        this.$store.commit('transient/SET_LOADING', true)
        this.selectedVehicle.attributes.notes = this.vehicleNotes
        this.selectedVehicle.attributes.monitrip = !this.selectedVehicle.attributes.monitrip
        await this.$alert(await axios.post('/pinmeapi/integration/moniitrip/startTrip', this.selectedVehicle).then(r => r.data), 'Monitriip')
      } catch (e) {
        if (e !== 'cancel') {
          this.selectedVehicle.attributes.monitrip = !this.selectedVehicle.attributes.monitrip
          await this.$alert((e.response && e.response.data) || e.message)
        }
      }
      this.$store.commit('transient/SET_LOADING', false)
    },
    async handleSubmitVehicleForm() {
      this.percentage = 0
      const v = { ...this.selectedVehicle }
      v.name = this.vehicleName
      v.groupId = this.selectedGroup
      v.model = this.vehicleModel
      v.category = this.selectedCategory

      v.attributes.driverUniqueId = this.driverUniqueId
      v.attributes.notes = this.vehicleNotes
      v.attributes.fuel_tank_capacity = this.vehicleFuelTankCapacity
      this.updateDeviceAttributes(v, this)
      try {
        this.$store.commit('transient/SET_LOADING', true)
        await traccar.updateDevice(this.selectedVehicle.id, v)

        if (this.calibrateTemperature) {
          await this.addComputedPermission(v.id)
        }

        if (this.applyConfigurationsToAll) {
          await this.updateAllVehicles()
        }

        await pinmeapi.driverAttribute(this.selectedVehicle.id)
        this.vehicleUpdated(v)
      } catch (reason) {
        if (reason.response) {
          if (reason.response.data.startsWith('Manager access required') ||
            reason.response.data.startsWith('Account is readonly')) {
            this.$message({
              message: this.$t('settings.vehicle_edit_not_allowed'),
              type: 'warning',
              duration: 5 * 1000
            })
          }
        } else {
          Vue.$log.error(reason)
          await this.$alert(reason)
        }
      }
      this.$store.commit('transient/SET_LOADING', false)
    },
    vehicleUpdated(updatedDevice) {
      this.isOpenVehicleForm = false
      this.selectedVehicle.name = updatedDevice.name
      this.selectedVehicle.groupId = updatedDevice.groupId
      this.selectedVehicle.category = updatedDevice.category
      this.selectedVehicle.model = updatedDevice.model
      this.selectedVehicle.attributes.fuel_tank_capacity = updatedDevice.attributes.fuel_tank_capacity
      this.selectedVehicle.attributes.driverUniqueId = updatedDevice.attributes.driverUniqueId
      this.selectedVehicle.attributes.notes = updatedDevice.attributes.vehicleNotes
      this.updateDeviceAttributes(this.selectedVehicle, updatedDevice.attributes, false)
      this.$message({
        type: 'success',
        message: this.$t('settings.vehicle_updated')
      })
      serverBus.$emit('deviceChanged', this.selectedVehicle)
      this.clearFormData()
    },
    updateDeviceAttributes(d, data, convert = true) {
      d.attributes.speedLimit = convert ? data.speedLimit / 1.85200 : data.speedLimit
      d.attributes.overspeedMinimalDuration = convert ? data.overspeedMinimalDuration * 60 : data.overspeedMinimalDuration
      d.attributes.overspeedByRoad = data.overspeedByRoad
      d.attributes.overspeedThreshold = data.overspeedThreshold
      d.attributes.minIdleTime = convert ? data.minIdleTime * 60 : data.minIdleTime
      d.attributes.minUnidentifiedDriverTime = convert ? data.minUnidentifiedDriverTime * 60 : data.minUnidentifiedDriverTime
      d.attributes.routeColor = data.routeColorActive || data.routeColor
      d.attributes.minTemperature = data.minTemperature
      d.attributes.maxTemperature = data.maxTemperature
      d.attributes.calibrateTemperature = data.calibrateTemperature
      d.attributes.maxDigitalPortOpenTime = data.maxDigitalPortOpenTime
    },
    async updateAllVehicles() {
      this.percentage = 0
      let count = 0
      const chunkSize = 4
      for (let i = 0; i < this.devices.length; i += chunkSize) {
        await Promise.all(this.devices.slice(i, i + chunkSize).map(device => {
          device.category = this.selectedVehicle.category || device.category
          this.updateDeviceAttributes(device, this)
          return traccar.updateDevice(device.id, { ...device }, 4)
            .then(() => {
              this.$notify({ message: `OK ${device.name}`, type: 'success' })
            })
            .catch(e => this.$notify({ message: `${device.name} ${(e.response && e.response.data) || e.message}`, type: 'error' }))
            .finally(() => { this.percentage = Math.round(count++ / this.devices.length * 100) })
        }))
      }
    },
    handleAssociateGeofences(row) {
      this.$store.commit('transient/SET_LOADING', true)
      this.isOpenAssociateGeofencesForm = true
      this.dialogGeofences = []
      this.selectedVehicle = row
      traccar.geofencesByDevice(row.id)
        .then(geofences => {
          this.dialogGeofences = this.geofences.map(g => ({ id: g.id, name: g.name }))
          this.selectedVehicle.geofences = geofences
          this.vehicleGeofences = this.selectedVehicle.geofences.map(g => g.id)
        })
        .catch(e => this.$message.error(e.message))
        .finally(() => this.$store.commit('transient/SET_LOADING', false))
    },
    handleEdit(row) {
      const p = this.findFeatureByDeviceId(row.id)
      this.selectedVehicle = row
      this.selectedGroup = row.groupId
      this.selectedCategory = row.category
      this.vehicleName = row.name
      this.vehicleModel = row.model
      this.vehicleFuelTankCapacity = row.attributes.fuel_tank_capacity ? row.attributes.fuel_tank_capacity : 0
      if (p) {
        this.vehicleTotalKms = p.properties.attributes.totalDistance / 1000
      }
      this.speedLimit = Math.round(row.attributes.speedLimit * 1.85200)
      this.overspeedMinimalDuration = row.attributes.overspeedMinimalDuration ? row.attributes.overspeedMinimalDuration / 60 : 0
      this.overspeedByRoad = row.attributes.overspeedByRoad
      this.overspeedThreshold = row.attributes.overspeedThreshold
      this.vehicleNotes = row.attributes.notes
      this.routeColor = row.attributes.routeColor
      this.driverUniqueId = row.attributes.driverUniqueId
      this.minTemperature = row.attributes.minTemperature
      this.maxTemperature = row.attributes.maxTemperature
      this.calibrateTemperature = row.attributes.calibrateTemperature
      this.maxDigitalPortOpenTime = row.attributes.maxDigitalPortOpenTime
      this.minIdleTime = row.attributes.minIdleTime ? row.attributes.minIdleTime / 60 : 0
      this.minUnidentifiedDriverTime = row.attributes.minUnidentifiedDriverTime ? row.attributes.minUnidentifiedDriverTime / 60 : 0
      this.isOpenVehicleForm = !this.isOpenVehicleForm
    },
    handleDoubleClick(row) {
      this.handleEdit(row)
    },
    async addComputedPermission(deviceId) {
      const permission = {
        deviceId: deviceId,
        attributeId: alertType.calibrateTemperatureComputedId
      }
      await pinmeapi.addPermission(permission)
    },
    alertSpeedRenderer(row, column, cellValue) {
      if (cellValue) {
        return Math.round(cellValue * 1.85200)
      } else {
        return ''
      }
    },
    groupRenderer(row, column, cellValue) {
      if (cellValue) {
        const group = vm.$store.state.user.groups.find((g) => g.id === cellValue)
        return (group && group.name) || ''
      }
      return ''
    },
    clearFormData() {
      this.vehicleName = ''
      this.selectedGroup = null
      this.selectedCategory = null
      this.vehicleModel = ''
      this.speedLimit = 0
      this.overspeedMinimalDuration = 0
      this.overspeedByRoad = false
      this.overspeedThreshold = 0
      this.vehicleTotalKms = 0
      this.vehicleKms = 0
      this.vehicleNotes = ''
      this.vehicleDateKms = null
      this.vehicleFuelTankCapacity = 0
      this.calibrateTemperature = 0
      this.applyConfigurationsToAll = false
    },
    renderFunc(h, option) {
      return <span title={option.name}>{option.name}</span>
    },
    doNothing(scope) {
      /* this method is here because we need the attribute 'slot-scope = "scope"' on the template
         for search box to work, but to be able to commit the variable 'scope' it must be used*/
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../../styles/element-variables.scss';

  .form-item-block-left{
    display: table-cell;
    width: 200px;
    padding-right: 30px;
  }
  .form-item-block-right{
    width: 200px;
    display: table-cell;
  }

  .el-form-item {
    margin-bottom: 5px
  }

  .form-item-block {
    width: 100%;
    display: table;
    margin-bottom: 5px
  }

  .form-item-row {
    display: table-row;
  }

  .el-button--small {
    padding: 9px 9px;
  }

  .modalFormKms {
    width: 300px;
    margin: 0 auto;
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px 3px;
    transition: all 0.2s ease-in;
  }

  .el-pagination__total{
    color: $--color-primary
  }

  .el-table  td:last-child {
    font-size: 12px;
  }

  .el-table .el-cell {
    word-break: normal !important;
  }

  .el-input-number-fix {
    width: 200px;
    text-align: left;
  }
  .el-button--mini {
    padding: 7px 7px;
  }
</style>
